import { sessionFromCustomerPartnerIdAtom, sessionPartnerIdAtom } from '@/utils/atoms';
import { useAtom } from 'jotai';

type UsePartnerId = {
	sessionPartnerId: string | null,
	setPartnerId: ( partnerId: string | null ) => void,
	deletePartnerId: () => void
};

type UseCustomerFromPartnerId = {
	sessionCustomerFromPartnerId: string | null,
	setCustomerFromPartnerId: ( customerFromPartnerId: string | null ) => void,
	deleteCustomerFromPartnerId: () => void
};

export function usePartnerId(): UsePartnerId {
	const [ sessionPartnerId, setSessionPartnerId ] = useAtom( sessionPartnerIdAtom );
	
	const setPartnerId = ( partnerId: string ): void => {
		if ( typeof window === 'undefined' || !( 'sessionStorage' in window ) ) {
			return;
		}
		sessionStorage.setItem( 'partnerId', partnerId );
		setSessionPartnerId( partnerId );
	};
	
	const deletePartnerId = (): void => {
		if ( typeof window === 'undefined' || !( 'sessionStorage' in window ) ) {
			return;
		}
		sessionStorage.removeItem( 'partnerId' );
		setSessionPartnerId( null );
	};
	
	return { sessionPartnerId, setPartnerId, deletePartnerId };
}

export function useCustomerFromPartnerId(): UseCustomerFromPartnerId {
	const [ sessionCustomerFromPartnerId, setSessionCustomerFromPartnerId ] = useAtom( sessionFromCustomerPartnerIdAtom );
	
	const setCustomerFromPartnerId = ( customerFromPartnerId: string ): void => {
		if ( typeof window === 'undefined' || !( 'sessionStorage' in window ) ) {
			return;
		}
		sessionStorage.setItem( 'c_partnerId', customerFromPartnerId );
		setSessionCustomerFromPartnerId( customerFromPartnerId );
	};
	
	const deleteCustomerFromPartnerId = (): void => {
		if ( typeof window === 'undefined' || !( 'sessionStorage' in window ) ) {
			return;
		}
		sessionStorage.removeItem( 'c_partnerId' );
		setSessionCustomerFromPartnerId( null );
	};
	
	return {
		sessionCustomerFromPartnerId,
		setCustomerFromPartnerId,
		deleteCustomerFromPartnerId,
	};
}
